// Slickの設定
window.onload = function(){
    $('.slick-carousel-wrapper').not('.slick-initialized').slick({
    autoplay:true,
    autoplaySpeed:3500,
    accessibility: true,
    arrows:true,
    fade:true,
    focusOnSelect:true,
});
// Slickの再生・停止
$('.slick-stop').on('click', function() {
    $('.slick-carousel-wrapper')
        .slick('slickPause')
});
$('.slick-play').on('click', function() {
    $('.slick-carousel-wrapper')
        .slick('slickPlay')
});
// フォームの入力補助　カレンダー
$('#calendar').datepicker();
}