// Global Nav Position Setting
document.addEventListener('DOMContentLoaded', function () {
    const mq = window.matchMedia('(max-width: 640px)');
    function checkBreakPoint(mq) {
        if (mq.matches) {
            $('.gNav').insertBefore('#offCanvas .sp-nav-footer');
            $('.subNav').prependTo('#offCanvas .sp-nav-footer');
            $('.search-form-wrapper').insertAfter('.subNav');
        } else {
            $('.gNav').insertAfter('#page-top');
            $('.subNav').appendTo('.header-menu');
            $('.search-form-wrapper').prependTo('.header-menu');
        }
    }
    mq.addListener(checkBreakPoint);
    checkBreakPoint(mq);

// トップページ壁紙のランダム変更
$(function () {
	var num = Math.ceil(3 * Math.random());
	$('div.title-header-content').addClass('yaguchi-pattern' + num);
});

});
